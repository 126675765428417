.CakeAwardsCard {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  background: #E3D9FF;
}

.CakeAwardsCard-textContent {
  margin-left: 15px;
  max-width: 200px;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.08em;
  padding: 5px 0;
  color: #262931;
}

.CakeAwardsCard-textContent-inCart {
  margin-left: 4px;
}

.CakeAwardsCard-textContent b {
  font-weight: 500;
}

.CakeAwardsCard-tooltipButton {
  margin-left: auto;
  border: 0.5px solid #636363;
  color: #636363;
  box-sizing: border-box;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  background: transparent;
  margin-right: 17px;
}

.CakeAwardsCard-tooltipButton svg path {
  fill: currentColor;
}

.CakeAwardsCard-tooltipButton:hover {
  background: #fff;
  border-color: #fff;
  color: #636363;
}

/*.CakeAwardsCard-tooltipButton:hover svg path {*/
/*  fill: #636363;*/
/*}*/

.CakeAwardsCard-tooltipActive {
  background: #fff;
  border-color: #fff;
  color: #636363;
}

/*.CakeAwardsCard-tooltipActive svg path {*/
/*  fill: #636363;*/
/*}*/

.CakeAwardsCard-tooltip {
  left: 0;
  top: calc(100% + 12px);
  position: absolute;
  background: #9456b2;
  padding: 15px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.08em;
  color: #ffffff;
  text-align: left;
}

.CakeAwardsCard-tooltip:before {
  content: '';
  border: solid transparent;
  position: absolute;
  bottom: 98%;
  right: 20px;
  border-bottom-color: #9456b2;
  border-width: 9px;
  margin-right: -1px;
}

.CakeAwardsCard-tooltipText {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.08em;
  color: #ffffff;
  text-align: left;
  max-width: 300px;
}

.CakeAwardsCard-image {
  height: 56px;
}

@media (max-width: 1200px) {
  .CakeAwardsCard-tooltip {
    padding-right: 80px;
  }
}

@media (max-width: 1045px) {
  .CakeAwardsCard-tooltip {
    padding-right: initial;
  }
}

@media (max-width: 840px) {
  .CakeAwardsCard-textContent {
    max-width: 100%;
  }

  .CakeAwardsCard-tooltip {
    padding-right: 25px;
  }
}

@media (max-width: 500px) {
  .CakeAwardsCard-textContent {
    max-width: 200px;
  }
}

@media (max-width: 350px) {
  .CakeAwardsCard-textContent {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.08em;
  }

  .CakeAwardsCard-tooltip {
    padding: 10px 18px 8px 14px;
    padding-right: 10px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.08em;
  }

  .CakeAwardsCard-image {
    width: 106px;
    height: 52px;
  }
}

@media (max-width: 330px) {
  .CakeAwardsCard-textContent {
    margin-left: 5px;
  }
}
