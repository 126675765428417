.PickPointDialog {
  width: 960px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
}

.PickPointDialog-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PickPointDialog-loader--disabled {
  display: none;
}

@media (max-width: 960px) {
  .PickPointDialog {
    max-width: 840px;
    /* margin: 16px; */
  }
}

.PickPointDialog-table {
  position: absolute;
  background-color: transparent;
  top: 158px;
  left: 0;
  width: 100%;
  z-index: 999;
  height: 74%;
  pointer-events: none;
}

.PickPointDialog-title {
  font-weight: normal;
}

.PickPointDialog-filter {
  padding: 0 40px;
  margin-bottom: 21px;
  box-sizing: border-box;
  position: relative;
}
.PickPointDialog-filterDropdown {
  position: absolute;
  top: 44px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 80px);
  z-index: 9999;
  background-color: #f8f8f8;
  padding: 5px 0;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 150%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.PickPointDialog-filterDropdown--closed,
.PickPointDialog-filterDropdown:empty {
  display: none;
}
.PickPointDialog-dropdownItem {
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: 1px solid #ebebeb;
}
.PickPointDialog-dropdownItem:hover {
  background-color: #fff;
}
.PickPointDialog-noResults {
  text-align: center;
  padding: 16px;
}

.PickPointDialog-map {
  width: 100%;
  height: 450px;
}
.PickPointDialog-mapWrapper {
  position: relative;
}
.PickPointDialog-SelectionTabs {
  display: none;
  width: 100%;
  height: 42px;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
}
.PickPointDialog-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  background-color: #fff;
  outline: none;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.PickPointDialog-tab svg {
  margin: 0 10px 4px 0;
}
.SelectionTabs--list .PickPointDialog-tabList,
.SelectionTabs--map .PickPointDialog-tabMap {
  border: 1px solid var(--main-violet);
}
.PickPointDialog-filterAllCount {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.08em;
  text-align: center;
  color: #636363;
  margin-top: 15px;
}
@media (max-width: 840px) {
  .PickPointDialog {
    width: 100%;
  }
  .PickPointDialog-title {
    margin-bottom: -9px;
  }
  .PickPointDialog-SelectionTabs {
    display: flex;
  }
  .PickPointDialog-mapWrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }
  .PickPointDialog-map {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  .PickPointDialog-filter {
    padding: 0%;
    margin: 0 10px 15px;
  }
  .PickPointDialog-filterDropdown {
    width: 100%;
  }
  .PickPointDialog-table {
    position: relative;
    top: 0;
  }
  .SelectionTabs--map ~ .PickPointDialog-table,
  .SelectionTabs--list ~ .PickPointDialog-mapWrapper {
    position: absolute;
    left: -9999px;
    top: -9999px;
  }
  .SelectionTabs--map ~ .PickPointDialog-table.PickPointDialog-table--search {
    left: 0;
    top: var(--search-bottom-offset);
    height: 200px !important;
  }
  .SelectionTabs--map ~ .PickPointDialog-table.PickPointDialog-table--search .PickPointRow-descriptionValue,
  .SelectionTabs--map ~ .PickPointDialog-table.PickPointDialog-table--search .Button {
    display: none;
  }
  .SelectionTabs--map ~ .PickPointDialog-table.PickPointDialog-table--search .PickPointRow {
    align-items: center;
  }
  .SelectionTabs--map ~ .PickPointDialog-table.PickPointDialog-table--search .PickPointRow-content {
    flex: 25 0;
  }
  .PickPointDialog-filterAllCount {
    margin-top: 20px;
  }
  .PickPointDialog-filterAllCount {
    text-align: left;
  }
}
