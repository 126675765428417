.item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 12px;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;

  &:last-child {
    margin-bottom: 0;
  }
}

.itemIconContent {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.itemText {
  position: relative;

  &:hover {
    &::before {
      content: "";
      position: absolute;
      bottom: 2px;
      left: 0;
      right: 0;
      display: inline-block;
      border-bottom: 1px solid;
      opacity: 1;
      border-color: currentColor;
      transition: opacity .2s ease-in-out;
    }
  }
}

.delivery-and-payment {
  font-size: 20px;
}

.rewards {
  font-size: 20px;
}

.track-order {
  font-size: 20px;
}

.faq {
  font-size: 19px;
}

.contacts {
  font-size: 18px;
}

.boldItem {
  font-weight: 500;
}

@media (max-width: 840px) {
  .rewards {
    font-size: 18px;
  }

  .contacts {
    font-size: 16px;
  }

  .delivery-and-payment {
    font-size: 18px;
  }
}

