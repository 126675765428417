.Input {
  position: relative;
  display: inline-flex;
}

.Input--hidingLabelOnFocus {
}

.Input--expanded {
  width: 100%;
}

.Input--disabled {
  opacity: 0.7;
}

.Input--active {
}

.Input--error {
}

.Input--visibleHint {
}

.Input-elGroup {
  flex: 1 1 auto;
  position: relative;
}

.Input-elGroup::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  border-top: 1px solid #000;
  transition: width 0.3s ease-in-out;
}

.Input--active .Input-elGroup::before {
  width: 100%;
}

.Input-elGroup::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  border-top: 1px solid #e02121;
  transition: width 0.3s ease-in-out;
}

.Input--error .Input-elGroup::after {
  width: 100%;
}

.Input-el {
  width: 100%;
  display: inline-block;
  height: 44px;
  box-sizing: border-box;
  margin: 0;
  padding: 12px;
  border: none;
  background-color: #f9f9f9;
  font-family: inherit;
  line-height: 20px;
  font-size: 12px;
  font-weight: 300;
  color: #000;
  letter-spacing: inherit;
  appearance: none;
  outline: none;
  transition: background-color 0.3s ease-in-out, border-bottom 0.3s ease-in-out;
  border-radius: 0;
}

.Input--active .Input-el {
  background-color: transparent;
}

.Input--expanded .Input-el {
  width: 100%;
}

.Input--error .Input-el {
  background-color: #fdf4f4;
}

.Input-placeholder {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  font: inherit;
  font-size: 12px;
  letter-spacing: inherit;
  color: #636363;
  cursor: text;
  outline: none;
  appearance: none;
  transition: left 0.3s ease-in-out, top 0.3s ease-in-out, font-size 0.2s linear, opacity 0.3s linear;
}

.Input--active .Input-placeholder {
  left: 0;
  top: -6px;
  font-size: 10px;
  cursor: default;
}

.Input--hidingLabelOnFocus.Input--active .Input-placeholder {
  opacity: 0;
}

.Input-hint {
  position: absolute;
  left: 0;
  top: calc(100% + 3px);
  line-height: 1.2;
  font-size: 10px;
  font-weight: 300;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.Input--error .Input-hint {
  color: #e02121;
}

.Input--visibleHint .Input-hint {
  opacity: 1;
}

.Input-prepended,
.Input-appended {
  position: absolute;
  display: inline-block;
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.7;
}

.Input-prepended {
  left: 16px;
}

.Input-appended {
  right: 16px;
}
