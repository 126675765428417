.menuHeader {
  padding: 8px 0;
}

.headerContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  flex: 0 0 56px;
}

.headerButton {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 28px;
  line-height: 1;
  vertical-align: top;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
