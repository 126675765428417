.plaitBanner {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  margin-top: 72px;
  padding-top: 32px;
  border-top: 1px solid #e7e7e7;

  @media (max-width: 374px) {
    gap: 4px;
    margin-top: 24px;
    padding-top: 24px;
  }
}

.text {
  color: #333333;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  line-height: 26px;

  @media (max-width: 374px) {
    font-size: 14px;
    line-height: 16px;
  }
}

.phone {
  color: #333333;
  font-weight: 700;
  font-size: 28px;
  font-family: "Montserrat", sans-serif;
  line-height: 38px;
  text-decoration: none;

  &:hover {
    color: var(--main-violet);
  }

  @media (max-width: 374px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.site {
  color: #333333;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  line-height: 28px;
  text-decoration: none;

  &:hover {
    color: var(--main-violet);
  }

  @media (max-width: 374px) {
    font-size: 18px;
    line-height: 24px;
  }
}
