.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(152, 152, 152, 0.9);
}
