html,
body {
  height: 100%;
}

body,
#root {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-family: 'HelveticaNeue', sans-serif;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.08em;
}

@media (max-width: 640px) {
  body {
    font-size: 11px;
  }
}

#root > .loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lazyload-wrapper {
  height: 100%;
  min-height: fit-content;
}

button {
  color: currentColor;
}

:root {
  --main-violet: #916EB8;
  --light-violet: #F4F0FF;
}

.visible-desktop {
  @media (max-width: 1279.98px) {
    display: none;
  }
}

.visible-mobile {
  @media (min-width: 1280px) {
    display: none;
  }
}

.text-nowrap {
  white-space: nowrap;
}
