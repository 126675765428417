.GiftsDialog {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 15px;
  overflow-y: auto;
  max-height: 80vh;
}

.GiftsDialog__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 198px;
}

.GiftsDialog__img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 10px;
}

.GiftsDialog__img img {
  width: 150px;
  height: 150px;
}

.GiftsDialog__title {
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  margin: 0 0 10px 0;
}

.GiftsDialog__button .Button {
  min-width: 198px;
}

.GiftsDialog__item.error .GiftsDialog__title {
  color: #cb6075;
}

@media (max-width: 840px) {
  .Dialog-title {
    text-align: center;
  }
}
