.nav {
  display: flex;
  flex-direction: column;
}

.item {
  margin-bottom: 12px;
  font-weight: 300;
  font-size: 18px;
  text-transform: uppercase;
  color: #000;
  position: relative;
  width: fit-content;

  &:hover {
    &::before {
      content: "";
      position: absolute;
      bottom: 2px;
      left: 0;
      right: 0;
      display: inline-block;
      border-bottom: 1px solid;
      opacity: 1;
      border-color: currentColor;
      transition: opacity .2s ease-in-out;
    }
  }

  &:nth-child(2) {
    margin-bottom: 24px;
  }
}

a.item {
  text-decoration: none;
}

@media (max-width: 840px) {

  .nav {
    margin-top: 48px;
  }

  .item {
    margin-bottom: 8px;
    font-size: 24px;
  }
}
