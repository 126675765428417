.iconContainer {
  display: inline-block;
  vertical-align: top;
}

.xxsmall {
  min-width: 16px;
  height: 16px;
}

.xsmall {
  min-width: 18px;
  height: 18px;
}

.small {
  min-width: 24px;
  height: 24px;
}

.inline {
  min-width: 1em;
  height: 1em;
  line-height: 1;
}

.medium {
  min-width: 32px;
  height: 32px;
}

.large {
  min-width: 40px;
  height: 40px;
}
