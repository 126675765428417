.IconFlatLoader {
  display: block;
  fill: currentColor;
  color: inherit;
  height: 100%;
}

.IconFlatLoader-first {
  animation-name: jump;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

.IconFlatLoader-second {
  animation-name: jump;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-delay: 0.15s;
}

.IconFlatLoader-third {
  animation-name: jump;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-delay: 0.3s;
}

@keyframes jump {
  0% {
    transform: translate3d(0, 0, 0);
  }

  20% {
    transform: translate3d(0, -20%, 0);
  }

  40% {
    transform: translate3d(0, 0, 0);
  }
}
