.modal {
  position: relative;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 86px 56px 40px;
  min-width: 280px;
  background-color: rgb(255, 255, 255);
}

.rightOrientation {
  min-width: 432px;
  padding: 0 32px;
}

.container {
  display: flex;
  min-height: 100%;
}

.close {
  position: absolute;
  top: 24px;
  right: 24px;
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 36px;
  line-height: 1;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  appearance: none;
}

@media (max-width: 840px) {
  .modal {
    width: 100%;
    padding: 0;
  }

  .close {
    display: none;
  }
}
