.AppliedPromocode {
  display: flex;
  align-items: center;
  color: var(--main-violet);
}

.AppliedPromocode-el {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.AppliedPromocode-elIcon {
  margin-right: 2px;
}

.AppliedPromocode-elText {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.AppliedPromocode-price {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  margin-right: 8px;
}

.AppliedPromocode-cancelHolder {
  flex: 0 0 auto;
  line-height: 1;
}

.AppliedPromocode-cancelButton {
  display: inline-block;
  margin: 0;
  margin-top: 1px;
  padding: 4px;
  border: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  outline: none;
  appearance: none;
  transition: opacity 0.2s ease-in-out;
}

.AppliedPromocode-cancelButton:hover {
  opacity: 0.7;
}

.AppliedPromocode-cancelButton:focus {
  opacity: 0.7;
}

.AppliedPromocode-cancelButton:active {
  opacity: 1;
}
