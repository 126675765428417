.media {
  position: relative;
  display: inline-block;
  width: 100%;
}

.image {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.adaptable {
  height: 100%;

  .image {
    height: 100%;
    object-fit: cover;
  }
}

.imageHolder {
  height: 100%;
}

.withRatio {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
