.item {
  border-bottom: 1px solid #e6e6e6;
}

.title {
  flex: 1 1 auto;
  margin-right: 12px;
}

.itemHeader {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 6px 0;
  color: inherit;
  font-weight: 500;
  font-size: 10px;
  font-family: inherit;
  letter-spacing: inherit;
  text-align: left;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  appearance: none;

  &:focus {
    opacity: 0.8;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }
}

.content {
  display: none;
  padding-bottom: 24px;
  font-weight: 300;
  font-size: 12px;
}

.flexEndAligned {
  align-items: flex-end;
}

.noBorders {
  border-bottom: none;

  .itemHeader {
    padding: 12px 0;
  }
}

.noSideGaps {
  .content {
    padding-right: 0;
    padding-left: 0;
  }
}

