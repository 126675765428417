.PromocodeField {
  overflow: hidden;
  height: 44px;
}

.PromocodeField--withError {
  height: calc(44px + var(--error-height));
}

.PromocodeField-input {
  height: 44px;
}

.PromocodeField-error {
  padding-top: 4px;
  font-weight: 300;
  line-height: 14px;
  font-size: 10px;
  color: #e16887;
}
