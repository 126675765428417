.BasketPromoGift {
  display: flex;
  margin: 15px 0 0 0;
}

.BasketPromoGift-about {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.BasketPromoGift-img {
  position: relative;
  flex: 0 0 160px;
  margin-right: 12px;
}

.BasketPromoGift-img span {
  position: absolute;
  top: 5px;
  left: 5px;
  padding: 3px 5px;
  color: #fff;
  background-color: var(--main-violet);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .03em;
  z-index: 2;
}

.BasketPromoGift-img img {
  max-width: 100%;
  max-height: 160px;
  object-fit: cover;
  object-position: center;
}

.BasketPromoGift-title {
  margin: 0;
  margin-bottom: 6px;
  line-height: 1.2;
  font-size: 12px;
  font-weight: 300;
}

.BasketPromoGift-change {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #5e5e5e;
  border-bottom: 1px dashed;
  font-size: 12px;
  cursor: pointer;
}

.BasketPromoGift-count {
  margin-top: auto;
}

@media (max-width: 420px) {
  .BasketPromoGift-img {
    flex: 0 0 100px;
  }

  .BasketPromoGift-img img {
    max-height: 120px;
  }

  .BasketPromoGift-title {
    font-size: 10px;
  }

  .BasketPromoGift-img span {
    font-size: 8px;
    letter-spacing: .08em;
    padding: 3px 2px 2px 2px;
    line-height: 1;
  }
}
