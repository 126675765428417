.CakeAwardsFeature {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.CakeAwardsFeature-tooltip {
  left: 0;
  top: 0;
  z-index: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  position: absolute;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  background: #F4E9FD;
}

@media (min-width: 841px) {
  .CakeAwardsFeature-tooltip {
    width: 56px;
    height: 56px;
  }
}

.CakeAwardsFeature-img {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.CakeAwardsFeature-tooltip--x2 {

}

.CakeAwardsFeature-text {
  font-size: 16px;
  line-height: 16px;
  color: #000;
  font-weight: 400;
  padding-left: 12px;
  letter-spacing: normal;
}

.CakeAwardsFeature-text--twoDigits {
  padding-left: 5px;
}

.CakeAwardsFeature-text--x2 {

}

.CakeAwardsFeature-border {
  border-radius: 50%;
  border: 3px solid #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
