.InformAvalability-wrapper {
  position: relative;
  display: flex;
  min-height: 374px;
  width: 100%;
  width: 484px;
}
.InformAvalability-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 840px) {
  .InformAvalability-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    z-index: 1;
    width: 100%;
  }
}
.InformAvalability-title {
  font-size: 24px;
  line-height: 32px;
  margin: 20px 0 19px;
}
.InformAvalability-wrapperSuccess .InformAvalability-description {
  word-spacing: -1px;
}
.InformAvalability-description {
  font-size: 14px;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  margin-top: 0;
}
.InformAvalability-title,
.InformAvalability-description {
  padding: 0 13%;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.08em;
  color: #000;
  font-weight: 400;
  font-style: normal;
}
.InformAvalability-description {
  margin-bottom: 30px;
}
.InformAvalability-container .Form {
  padding: 0 12px;
}
.InformAvalability-container .Form .Input--visibleHint {
  margin-bottom: 12px;
}
.InformAvalability-container .Form .Input-el {
  padding: 0 10px;
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 0.08em;
}
.InformAvalability-container .Form .Input-el::placeholder {
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 0.08em;
  color: #636363;
  font-style: normal;
}
.InformAvalability-privacyPolicy {
  padding: 0 10%;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.08em;
  color: #636363;
  margin-top: 157px;
}
.InformAvalability-privacyPolicyLink {
  color: inherit;
}
.InformAvalability-privacyPolicyLink:active,
.InformAvalability-privacyPolicyLink:visited {
  color: inherit;
}

.InformAvalability-containerSuccess {
  display: flex;
}
.InformAvalability-contentSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 410px;
  padding: 0 37px 0 319px;
}
.InformAvalability-containerSuccess .InformAvalability-description {
  padding: 0;
  margin-bottom: 40px;
}
.InformAvalability-containerSuccess .Logo {
  width: 100px;
  margin-bottom: 21px;
}
.InformAvalability-imageSection {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.InformAvalability-imageDesktop {
  display: none;
}

@media (max-width: 839px) {
  .InformAvalability-contentSection {
    padding: 0;
    margin: auto;
  }
  .InformAvalability-contentSection .Button {
    width: 100%;
  }
  .InformAvalability-contentSection .Button-text {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.08em;
  }
  .InformAvalability-imageSection {
    position: relative;
    height: 213px;
    width: 100%;
    margin-bottom: 12px;
  }
  .InformAvalability-imageSection img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
  }
}
.InformAvalability-container .Input-elGroup::before,
.InformAvalability-container .Input-elGroup::after,
.ProductCard-counterGroupHolder .Input-elGroup::before,
.ProductCard-counterGroupHolder .Input-elGroup::after {
  border: none;
}
.ProductCard-counterGroupHolder .Input--error .Input-hint {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.08em;
  white-space: nowrap;
  margin-top: 1px;
  word-spacing: -2px;
}
@media (min-width: 840px) {
  .InformAvalability-containerSuccess {
    height: 374px;
  }
  .InformAvalability-containerSuccess .InformAvalability-contentSection {
    justify-content: flex-start;
    margin-top: 9px;
  }
  .InformAvalability-containerSuccess .Logo {
    margin-left: 8px;
  }
  .InformAvalability-containerSuccess .InformAvalability-title {
    margin-bottom: 11px;
  }
  .InformAvalability-containerSuccess .InformAvalability-description {
    padding: 0 40px;
    margin-bottom: 55px;
  }
  .InformAvalability-containerSuccess .Button--expanded {
    min-width: 194px;
  }
  .InformAvalability-container {
    top: 43.5%;
  }
  .InformAvalability-title,
  .InformAvalability-description {
    padding: 0 23%;
  }
  .InformAvalability-description {
    margin-bottom: 38px;
  }
  .InformAvalability-container .Form {
    padding: 0 94px;
  }
  .InformAvalability-privacyPolicy {
    margin-top: 38px;
  }
  .ProductCard-counterGroupHolder .Input--error .Input-hint {
    margin-top: 4px;
  }
  .InformAvalability-imageDesktop {
    display: block;
  }
  .InformAvalability-imageMobile {
    display: none;
  }
}
.InformAvalability-container .Button {
  margin-top: 15px;
}
.InformAvalability-container .Button-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.08em;
  text-align: center;
}
.InformAvalability-containerSuccess .InformAvalability-description-repeat {
  margin-bottom: 12px;
}
