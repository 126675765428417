.tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border: 1px solid #bfbfbf;
  border-radius: 50%;
  cursor: pointer;

  svg {
    path {
      fill: #bfbfbf;
    }
  }

  &:hover {
    border-color: #999999;

    svg {
      path {
        fill: #999999;
      }
    }
  }
}
