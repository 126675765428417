.videoHolder {
  position: relative;
  appearance: none;
}

.video {
  display: inline-block;
  width: 100%;
  object-fit: cover;
  vertical-align: top;
  outline: none;
}

.title {
  position: absolute;
  top: 12px;
  right: 0;
  left: 0;
  padding: 0 16px;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  transition: opacity 0.2s ease-in-out;
}

@media (max-width: 640px) {
  .title {
    font-size: 10px;
  }
}

.hiddenTitle {
  opacity: 0;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: 1;
  transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  appearance: none;
}

.playButton:focus {
  opacity: 0.8;
}

.playButton:hover {
  opacity: 0.8;
}

.fullClickable {
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transform: none;
}

.fullClickable:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.fullClickable:active {
  background-color: rgba(255, 255, 255, 0.1);
}

.hiddenBtn,
.hiddenBtn:focus,
.hiddenBtn:hover {
  opacity: 0;
  pointer-events: none;
}

.playIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 118px;
  height: 118px;
  color: #ffffff;
  line-height: 116px;
  text-align: center;
  border: 1px solid currentColor;
  border-radius: 50%;
}

@media (max-width: 840px) {
  .playIcon {
    width: 80px;
    height: 80px;
  }
}
