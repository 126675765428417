.toast {
  display: flex;
  align-items: center;
  padding: 20px 24px;
  color: inherit;
  text-decoration: none;
}

.main {
  max-width: 200px;
}

.title {
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

.productTitle {
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 1.2;
  text-transform: lowercase;
}

.info {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
}

.close {
  margin-left: auto;
}

.closeButton {
  margin: 0;
  padding: 0;
  color: #ffffff;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  appearance: none;
}
