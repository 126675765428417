.CustomScrollbar {
  flex: 1 1 auto;
  display: flex;
  --vertical-track-right: 0;
  --simplebar-width: 0;
}

.simplebar-track {
  width: var(--simplebar-width) !important;

  background-color: #edeef0 !important;
  cursor: pointer;
  transition: width 0.1s linear;
}

.simplebar-vertical {
  right: var(--vertical-track-right) !important;
  pointer-events: all !important;
}

.simplebar-horizontal {
  display: none !important;
}

.simplebar-scrollbar::before {
  left: 0;
  right: 0;
  background-color: var(--main-violet) !important;
  opacity: 1 !important;
}

[data-simplebar='init'] {
  flex: 1 1 auto;
}
