.Container {
  --side-padding: 32px;
  box-sizing: border-box;

  max-width: calc(1580px + 2 * var(--side-padding));
  margin: 0 auto;
  padding: 0 var(--side-padding);
}

@media (max-width: 1680px) {
  .Container {
    max-width: calc(1320px + 2 * var(--side-padding));
  }
}

@media (max-width: 1480px) {
  .Container {
    max-width: calc(1140px + 2 * var(--side-padding));
  }
}

@media (max-width: 1440px) {
  .Container {
    max-width: calc(1128px + 2 * var(--side-padding));
  }
}

@media (max-width: 1280px) {
  .Container {
    --side-padding: 16px;
  }
}

.Container--fullHeight {
  height: 100%;
}

.Container--fullWidth {
  width: 100%;
}
