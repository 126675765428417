.IconContainer {
  display: inline-block;
  height: 32px;
  min-width: 32px;
  vertical-align: top;
}

.IconContainer--size-medium {
}

.IconContainer--size-inline {
  height: 1em;
  min-width: 1em;
  line-height: 1;
}

.IconContainer--size-xxsmall {
  height: 16px;
  min-width: 16px;
}

.IconContainer--size-xsmall {
  height: 18px;
  min-width: 18px;
}

.IconContainer--size-small {
  height: 24px;
  min-width: 24px;
}
.IconContainer--size-large {
  height: 40px;
  min-width: 40px;
}
