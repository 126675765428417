.PickPointRow {
  display: flex;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 15px;
  border: none;
  background: #F8F8F8;
  font-family: inherit;
  font-weight: 300;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  outline: none;
  appearance: none;
  transition: background-color 0.2s ease-in-out;
  border-bottom: 1px solid #ececec;
}

.PickPointRow:hover {
  background-color: #fff;
}
.PickPointRow-titleValue .PickPointRow-companyLogoImage {
  display: none;
}
@media (max-width: 840px) {
  .PickPointRow:hover {
    background-color: transparent;
  }
  .PickPointRow-companyLogoImage:not(.PickPointRow-titleValue .PickPointRow-companyLogoImage) {
    display: none;
  }
  .PickPointRow-titleValue .PickPointRow-companyLogoImage {
    display: block;
    flex: none;
  }
}

.PickPointRow--selected {
  pointer-events: none;
  background-color: #fff;
}
.PickPointRow-content .Button-text svg {
  margin-left: 5px;
}


.PickPointRow-company {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-align: center;
  flex-direction: column;
  margin-bottom: 15px;
}

@media (max-width: 960px) {
  .PickPointRow-company {
    margin-right: 0;
    margin-bottom: 10px;
    flex: 0 0 auto;
    font-size: 14px;
  }
}

.PickPointRow-companyLogo {
  display: inline-block;
  width: 33px;
  height: 33px;
  /* background-color: pink; */
  margin-right: 8px;
}

.PickPointRow-companyLogoImage {
  color: transparent;
  flex: 1 0;
  margin-right: 5px;
}

.PickPointRow-titleValue {
  font-weight: 500;
  display: flex;
  align-items: center;
}

.PickPointRow-titleAdditional {
  margin-left: auto;
}

.PickPointRow-address {
  flex: 0 0 220px;
  margin-right: 36px;
  margin-top: 8px;
}

@media (max-width: 960px) {
  .PickPointRow-address {
    flex: 0 0 auto;
    margin-right: 0;
    margin-bottom: 12px;
    font-size: 10px;
    margin-top: 0;
  }
}

.PickPointRow-schedule {
  flex: 0 1 185px;
  font-family: inherit;
  margin: 0;
  margin-top: 8px;
}

@media (max-width: 960px) {
  .PickPointRow-schedule {
    flex: 0 0 auto;
    font-size: 10px;
    margin-top: 0;
  }
}
.SearchQuery-highlight,
.PickPointRow-highlight {
  background-color: #ff9ecc;
}
.PickPointRow-addressValue {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  margin: 5px 0 10px;
}
.PickPointRow-content,
.PickPointRow-descriptionValue,
.PickPointRow-workTime {
  display: flex;
  flex-direction: column;
}
.PickPointRow-workTime {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.08em;
}
.PickPointRow--selected {
  pointer-events: none;
}
.PickPointRow .Button {
  display: none;
  pointer-events: all;
  margin-top: 14px;
  min-width: auto;
  max-width: 206px;
}

.PickPointRow .Button.Button--variant-dark,
.PickPointRow.PickPointRow--selected .Button {
  display: inline-flex;
}
.PickPointRow-content {
  flex: 7 0;
  padding-left: 2px;
  box-sizing: border-box;
}

@media (max-width: 840px) {
  .PickPointRow .Button {
    max-width: 100%;
  }
  .PickPointRow-addressValue,
  .PickPointRow-selectedPickPoint-address {
    color: #636363;
  }
}
