.Counter {
  display: inline-block;
  height: 44px;
  box-sizing: border-box;
  background-color: #faeff1;
  border: 1px solid transparent;
  outline: none;
  transition: border 0.2s ease-in-out;
}

.Counter--flat {
  background-color: transparent;
}

.Counter-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 44px;
  padding: 0;
  margin: 0;
  border: none;
  color: #000;
  background-color: transparent;
  cursor: pointer;
  appearance: none;
  outline: none;
  vertical-align: middle;
  text-align: center;
  transition: opacity 0.2s ease-in-out;
}

.Counter-button:focus {
  opacity: 0.8;
}

.Counter-button:hover {
  opacity: 0.8;
}

.Counter-button:active {
  opacity: 0.8;
}

.Counter-button--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.Counter--withFocusedInput .Counter-button {
  opacity: 0;
}

.Counter-input {
  display: inline-block;
  padding: 10px 0;
  border: none;
  line-height: 14px;
  font-family: inherit;
  font-size: 12px;
  background-color: transparent;
  text-align: center;
  outline: none;
  vertical-align: middle;
}

.Counter-button:disabled {
  pointer-events: none;
  opacity: 0.5;
}

@media (max-width: 420px) {
  .Counter {
    height: 20px;
  }

  .Counter-button {
    width: 20px;
    height: 20px;
  }

  .Counter-input {
    padding: 0;
  }
}
