.PromocodeInput {
  display: flex;
  height: 44px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--main-violet);
}

.PromocodeInput--disabled {
  opacity: 0.7;
}

.PromocodeInput-el {
  flex: 1 1 auto;
  display: inline-block;
  height: 43px;
  box-sizing: border-box;
  margin: 0;
  padding: 12px;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: inherit;
  color: var(--main-violet);
  text-transform: uppercase;
  appearance: none;
  outline: none;
}

.PromocodeInput-el::placeholder {
  color: inherit;
  opacity: 0.7;
}

.PromocodeInput-submitButton {
  flex: 0 0 auto;
  display: inline-block;
  height: 43px;
  width: 44px;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: var(--main-violet);
  cursor: pointer;
  outline: none;
  appearance: none;
  transition: opacity 0.2s ease-in-out;
}

.PromocodeInput-submitButton:focus {
  opacity: 0.8;
}

.PromocodeInput-submitButton:hover {
  opacity: 0.8;
}

.PromocodeInput-submitButton:active {
  opacity: 1;
}

.PromocodeInput-submitButton:disabled {
  pointer-events: none;
}
