.PickPointTable {
  position: relative;
  width: 100%;
  height: 100%;
}

.PickPointTable-titleGroup {
  position: absolute;
  text-align: left;
  padding: 15px 20px;
  width: 100%;
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.08em;
  color: #767676;
  text-transform: uppercase;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
}

.PickPointTable-company {
  flex: 0 1 80px;
  margin-right: 24px;
}

.PickPointTable-noResults {
  text-align: center;
  padding: 10px;
}

.PickPointTable-address {
  flex: 0 0 230px;
  margin-right: 40px;
}

.PickPointTable-schedule {
  flex: 0 0 200px;
}

.PickPointTable-price {
  flex: 0 0 40px;
  margin-right: 44px;
}

.PickPointTable-listPositioner {
  pointer-events: all;
  position: absolute;
  /* 46px макетная высота блока с заголовком */
  top: 46px;
  width: 292px;
  height: calc(100% - 46px);
  display: flex;
  background-color: #fff;
}

.PickPointTable-list {
  width: 100%;
}

.PickPointTable-item {
  background: #f8f8f8;
}

@media (max-width: 840px) {
  .PickPointTable {
    position: static;
    height: 100%;
  }

  .PickPointTable-listPositioner {
    top: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  .PickPointTable-titleGroup {
    display: none;
  }
}
