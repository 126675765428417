.BasketHeaderButton {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 6px;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  outline: none;
  appearance: none;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}

.BasketHeaderButton:focus {
  opacity: 0.7;
}

.BasketHeaderButton:hover {
  opacity: 0.7;
}

.BasketHeaderButton:active {
  opacity: 1;
}

.BasketHeaderButton-amount {
  position: absolute;
  font-size: 12px;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
}
