.BasketProduct {
  position: relative;
  display: flex;
}

.BasketProduct-mediaCol {
  position: relative;
  flex: 0 0 160px;
  margin-right: 12px;
}

.BasketProduct-skeletonWrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.BasketProduct-skeletonItem {
  position: relative;
}

.BasketProduct-skeletonHolder {
  position: absolute;
  left: 0;
  top: -5px;
  right: 0;
  bottom: 7px;
}

.BasketProduct-skeletonBlock {
  display: block;
  line-height: 1;
}

.BasketProduct-contentCol {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-transform: uppercase;
}

.BasketProduct-title {
  margin: 0 0 6px;
  line-height: 1.2;
  font-size: 12px;
  font-weight: 300;
}

.BasketProduct-titleHidden {
  opacity: 0;
  visibility: hidden;
}

.BasketProduct-titleWrapper {
  position: relative;
}

.BasketProduct-titleSkeleton {
  margin-bottom: 5px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.BasketProduct-link {
  text-decoration: none;
  color: inherit;
}

.BasketProduct-link::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.BasketProduct-price {
  font-weight: 300;
  width: fit-content;
  position: relative;
}

.BasketProduct-price .Price--variant-previous,
.BasketProduct-price .Price--variant-current {
  font-size: 12px;
  line-height: 14px;
}

.BasketProduct-price .Price--variant-sale {
  font-size: 14px;
  line-height: 14px;
}

.BasketProduct-priceSkeleton {
  position: absolute;
  left: -3px;
  bottom: 0;
  top: -2px;
  right: -2px;
}

.BasketProduct-bottomGroup {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.BasketProduct-counter {
  position: relative;
  margin-left: -10px;
  margin-bottom: -12px;
}

.BasketProduct-counterSkeleton {
  display: flex;
  gap: 2px;
  padding-left: 4px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
}

@media (min-width: 421px) {
  .BasketProduct-counterSkeleton {
    gap: 10px;
    padding-left: 14px;
    padding-top: 2px;
  }
}

.BasketProduct-trashSkeleton {
  width: 30px;
  height: 30px;
}

@media (min-width: 421px) {
  .BasketProduct-trashSkeleton {
    width: 40px;
    height: 40px;
  }
}

.BasketProduct-trashHolder {
  position: relative;
}

.BasketProduct-trashButton {
  display: inline-block;
  margin: 0;
  padding: 8px;
  border: none;
  border-radius: 50%;
  background-color: rgba(199, 178, 222, .1);
  cursor: pointer;
  appearance: none;
  outline: none;
  transition: opacity 0.2s ease-in-out;
}

.BasketProduct-trashButton:focus {
  opacity: 0.8;
}

.BasketProduct-trashButton:hover {
  opacity: 0.8;
}

.BasketProduct-trashButton:active {
  opacity: 1;
}

.BasketProduct-cakeAwardsFeature {
  position: absolute;
  left: 3px;
  top: 3px;
  pointer-events: none;
}

.BasketProduct.not-available:before {
  content: '';
  position: absolute;
  background-color: #fff;
  opacity: .7;
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.BasketProduct.not-available .BasketProduct-trashHolder {
  z-index: 2;
}

@media( max-width: 420px) {
  .BasketProduct-mediaCol {
    flex: 0 0 100px;
  }

  .BasketProduct-title, .BasketProduct-price {
    font-size: 10px;
  }

  .BasketProduct-bottomGroup {
    align-items: center;
  }

  .BasketProduct-counter {
    margin: 0;
  }

  .BasketProduct-trashButton {
    padding: 6px;
  }

  .BasketProduct-cakeAwardsFeature {
    top: 3px;
  }

  .BasketProduct-cakeAwardsFeature .CakeAwardsFeature-imageFeature {
    height: 27px;
  }

  .BasketProduct-cakeAwardsFeature .CakeAwardsFeature-counter {
    width: 19px;
    height: 19px;
  }

  .BasketProduct-cakeAwardsFeature .CakeAwardsFeature-x2Status {
    width: 10px;
    height: 10px;
    padding-bottom: 0;
  }
}
