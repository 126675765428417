.RightSideDialog {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.RightSideDialog-container {
  display: flex;
  justify-content: flex-end;
  min-height: 100%;
}

.RightSideDialog-window {
  position: relative;
  min-width: 432px;
  padding: 0 32px;
  box-sizing: border-box;
  background-color: #fff;
}

@media (max-width: 840px) {
  .RightSideDialog-window {
    width: 100%;
    padding: 0;
  }
}
