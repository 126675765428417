.status {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 4px 6px;
  box-sizing: border-box;
  background: #F4F0FF;
  width: 100%;

  &Info {
    padding: 0;
  }
}

.noBackground {
  background: none;
  padding: 0;
}

.logo {
  width: 64px;
  height: 18px;
  margin-right: 3px;

  @media (min-width: 841px) {
    width: 63px;
    height: 16px;
    margin-right: 4px;
  }
}

.price {
  font-size: 11px;
  line-height: 13px;
  font-weight: 400;
  padding-bottom: 2px;
  letter-spacing: normal;

  &Info {
    font-size: 12px;
    line-height: unset;

    @media (min-width: 841px) {
      font-size: 11px;
      line-height: 13px;
    }
  }
}

.tooltip {
  margin-left: 5px;

  @media (min-width: 841px) {
    margin-left: 10px;
  }
}
