@import "src/utils.scss";

.Button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 216px;
  height: 44px;
  box-sizing: border-box;
  padding: 3px 40px;
  border: 1px solid #916EB8;
  background-color: transparent;
  font-family: inherit;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  letter-spacing: inherit;
  vertical-align: top;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  appearance: none;
  outline: none;
  transition: background-color 0.3s ease-out, color 0.3s ease-out;
  white-space: pre-wrap;
}

.Button--size-small {
  padding: 0 24px;
  min-width: fit-content;
  height: 35px;
}

.Button--size-md {
  padding: 5px 35px;
  height: 54px;
}

.Button--size-big {
  padding: 8px 40px;
  height: 54px;
}

.Button--size-big .Button-text {
  font-size: 12px;
}

@media (min-width: 1280px) {
  .Button--size-big {
    padding: scaleToViewport(11, 1920) scaleToViewport(40, 1920);
    height: scaleToViewport(80, 1920);
  }

  .Button--size-big .Button-text {
    font-size: scaleToViewport(18, 1920);
  }
}

/* @media (max-width: 640px) {
  .Button {
    min-width: 160px;
    height: 38px;
    padding: 0 24px;
    font-size: 10px;
  }
} */

.Button:focus {
  background-color: #2E2E2E;
  color: #fff;
}

.Button:hover {
  background-color: #2E2E2E;
  border-color: #2E2E2E;
  color: #fff;
}

.Button:active {
}

.Button:disabled {
}

.Button--primary {
  background-color: #916EB8;
  color: #fff;
}

.Button--primary:focus {
  background-color: transparent;
  color: #000;
}

.Button--primary:hover {
  background-color: #2E2E2E;
  border-color: #2E2E2E;
  color: #fff;
}

.Button--variant-hoverViolet {
  border-color: #9456b2;
  color: #2E2E2E;
  font-weight: 700;
}

.Button--variant-hoverViolet:focus,
.Button--variant-hoverViolet:active,
.Button--variant-hoverViolet:hover {
  background: #9456b2;
  border-color: #9456b2;
  color: #fff;
}

.Button--variant-violet,  .Button--variant-violetDark {
  background-color: #9456b2;
  color: #fff;
}

.Button--variant-violet {
  border: 1px solid #9456b2;
}

.Button--variant-violetDark {
  border: none;
}

.Button--variant-violet:focus, .Button--variant-violetDark {
  background-color: #9456b2;
}

.Button--variant-violet:hover,
.Button--variant-violet:focus {
  background-color: #9456b2;
}

.Button--variant-violetDark:hover,
.Button--variant-violetDark:active,
.Button--variant-violetDark:focus {
  background-color: #2E2E2E;
}

.Button--variant-fillViolet {
  border: none;
  background-color: #E3D9FF;
  color: #636363;
  font-weight: 500;
}

.Button--variant-fillViolet:focus {
  background-color: #916EB8;
  color: #fff;
}

.Button--variant-fillViolet:hover {
  background-color: #916EB8;
  color: #fff;
}

.Button--variant-fillViolet:active {
  background-color: #916EB8;
  color: #fff;
}

.Button--variant-fillPink {
  border: none;
  background-color: #fff7f8;
  color: #636363;
  padding: 0;
}
.Button--variant-violetDashed {
  border: 1px dashed #916EB8;
  color: #916EB8;
  opacity: 1 !important;
}
.Button--disabled {
  pointer-events: none;
  opacity: 0.5;
}

.Button--variant-transperent {
  border: 1px solid #fff;
  color: #fff;
  background-color: transparent;
}

.Button--variant-transperent:hover {
  background-color: #2E2E2E;
}

.Button--variant-transperent:active {
  background-color: #2E2E2E;
}
.Button--variant-dark {
  background-color: #000;
  border: none;
}
.Button--variant-dark:hover,
.Button--variant-dark:active,
.Button--variant-dark:focus {
  background-color: #000;
  border: none;
}
.Button--variant-dark .Button-text {
  color: #fff;
}
.Button--expanded {
  width: 100%;
}

.Button-icon {
  display: inline-block;
  margin-left: 8px;
  line-height: 1;
  vertical-align: middle;
}

.Button-text {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  font-style: normal;
  font-weight: 550;
  line-height: 12px;
  letter-spacing: 0.08em;
  text-align: center;
}

.Button:enabled .Button-text {
  /* на ios не всегда срабатывает onClick обработчик https://stackoverflow.com/questions/18914568/make-onclick-work-on-iphone/18914640#18914640 */
  cursor: pointer;
}

@media (min-width: 840px) {
  .Button-text {
    font-size: 12px;
    line-height: 14px;
  }
}

.Button--variant-hoverViolet .Button-text{
  letter-spacing: 0.96px;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
}
