.PickPointDialog-selectedPickPoint {
    background-color: white;
    position: absolute;
    visibility: hidden;
    z-index: 999;
}

.PickPointDialog-selectedPickPoint-closeButton {
    display: flex;
    justify-content: flex-end;
}

.PickPointDialog-selectedPickPoint-chooseButton {
    background-color: var(--main-violet);
    text-transform: uppercase;
}

.PickPointDialog-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 10px 10px 10px;
}

.PickPointRow-selectedPickPoint-time {
    margin-bottom: 12px;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
}

.PickPointRow-selectedPickPoint-price {
    text-align: center;
    margin: 10px 0;
}

.PickPointRow-selectedPickPoint-address {
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: 0.08em;
    text-align: left;
    margin-top: 5px;
}
.PickPointDialog-wrapper .Button-text svg {
    margin-left: 5px;
}
@media (max-width: 840px) {
    .PickPointDialog-selectedPickPoint {
        position: fixed;
        width: 90%;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        visibility: visible;
    }
}
