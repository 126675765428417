.card {
  padding: 24px 16px;
  border: 1px solid #f1f1f1;
  border-radius: 16px;
  box-shadow: 0 14px 14px rgba(116, 116, 116, 0.09);

  @media (min-width: 1280px) {
    padding: 32px;
    border-radius: 24px;
  }
}

.title {
  font-weight: 600;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  line-height: 24px;
}

.textParts {
  display: grid;
  grid-template-columns: 27% 3fr;
  gap: 12px;

  @media (min-width: 1280px) {
    display: none;
  }
}

.partStatus {
  height: 6px;
  background: #f1f1f1;
  border-radius: 3px;

  &Active {
    background: #6969ff;
  }
}

.partInfo {
  margin-top: 16px;
}

.parts {
  display: grid;
  grid-template-columns: 27% 1fr 1fr 1fr;
  gap: 12px;
  margin-top: 24px;

  .partInfo {
    display: none;
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    margin-top: 32px;

    .partInfo {
      display: block;
    }
  }
}

.partLabel {
  color: #808080;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.partValue {
  margin-top: 8px;
  font-weight: 600;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  line-height: 16px;

  @media (min-width: 1280px) {
    font-size: 24px;
    line-height: 20px;
  }
}
