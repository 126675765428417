.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;
  padding: 40px 20px;
}

.box {
  position: relative;
}
