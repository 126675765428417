.overlay {
  z-index: 10000 !important;
  scrollbar-width: none !important;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.modal {
  display: block !important;
  max-width: 594px;
  height: fit-content !important;
  margin: 100px auto 0 !important;
  padding: 28px 23px !important;
  letter-spacing: normal;
  background: #ffffff;
  border-radius: 32px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  @media (min-width: 840px) {
    margin: 100px auto 100px !important;
    padding: 36px 32px !important;
    border-radius: 32px;
  }
}

.content {
  padding: 8px 32px 32px !important;

  @media (max-width: 374px) {
    padding: 8px 16px 24px !important;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 44px;

  @media (min-width: 840px) {
    margin-bottom: 52px;
  }
}

.closeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  cursor: pointer;

  @media (min-width: 840px) {
    width: 40px;
    height: 40px;
    border-radius: 12px;
  }
}

.closeIcon {
  width: 16px;
  height: 16px;

  @media (min-width: 840px) {
    width: 24px;
    height: 24px;
  }
}

.logo {
  width: 140px;
  height: 32px;
}

.badgeStatus {
  display: flex;
  gap: 24px;
  align-items: center;
  box-sizing: border-box;
  width: fit-content;
  padding: 8px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  background: #f4f7ff;
  border-radius: 30px;

  @media (min-width: 840px) {
    padding: 8px 23px;
  }
}

.title {
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
}

.featuresList {
  margin: 0;
  margin-top: 20px;
  padding: 0;
  list-style-type: none;

  @media (min-width: 840px) {
    margin-top: 40px;
  }
}

.featureListItem {
  display: flex;
  align-items: center;
  padding: 20px 0;
  color: #333333;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 1px solid #e7e7e7;

  span {
    max-width: 340px;
  }

  @media (min-width: 840px) {
    padding: 24px 0;
    font-size: 18px;
    line-height: 26px;
  }
}

.featureIcon {
  width: 32px;
  height: 32px;
  margin-right: 24px;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 840px) {
    width: 40px;
    height: 40px;
  }
}

.accordionWrapper {
  margin-top: 24px;

  @media (min-width: 840px) {
    margin-top: 40px;
  }
}

.accordion {
  max-height: unset;
  padding: 24px;
  border: 1px solid #e7e7e7;
  border-radius: 24px;
}

.accordionTitle {
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  text-transform: none;

  @media (min-width: 840px) {
    font-size: 18px;
    line-height: 26px;
  }
}

.accordionIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 8px;

  svg {
    width: 19px;
    height: 19px;
  }

  @media (min-width: 840px) {
    width: 40px;
    height: 40px;
    border-radius: 12px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.accordionDetails {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.detailsItem {
  &:first-child {
    margin-top: 25px;
  }

  &:not(:first-child) {
    margin-top: 25px;
  }
}

.detailsTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.detailsText {
  margin-top: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
