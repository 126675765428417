.ShopTable {
    overflow: hidden;
    width: 820px;
}

@media (max-width: 840px) {
    .ShopTable {
        overflow: auto;
        width: 100%;
    }
}

.ShopTable-titleGroup {
    display: flex;
    padding: 16px 40px;
    background-color: #f9f9f9;
    font-size: 12px;
    font-weight: 500;
    color: #767676;
    text-transform: uppercase;
}

@media (max-width: 960px) {
.ShopTable-titleGroup {
    display: none;
}
}

.ShopTable-index {
    flex: 0 1 30%;
    padding: 0 8px;
    text-align: center;
    box-sizing: border-box;
}

.ShopTable-adress{
    flex: 0 1 35%;
    padding: 0 8px;
    box-sizing: border-box;
}

.ShopTable-listPositioner {
    position: relative;
    max-height: calc(100vh - 400px);
    display: flex;
}

@media (max-width: 840px) {
    .ShopTable-listPositioner {
        /* overflow: auto; */
        max-height: calc(100vh - 160px);
    }
}

.ShopTable-list {
    width: 100%;
  }

.ShopTable-row {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 32px 40px;
    border: none;
    background-color: transparent;
    font-weight: 300;
    font-size: 12px;
    text-align: left;
    outline: none;
    appearance: none;
    transition: background-color 0.2s ease-in-out;
    border-bottom: 1px solid #ececec;
}

@media (max-width: 960px) {
    .ShopTable-row {
        flex-direction: column;
        background: #f8f8f8;
        padding: 24px 14px;
        align-items: flex-start;
        cursor: pointer;
    }

    .ShopTable-row:hover {
        background-color: #edf0f5;
    }

}

.ShopTable-rowIndex {
    flex: 0 0 30%;
    padding: 0 8px;
    text-align: center;
    box-sizing: border-box;
    color: #262931;
}

@media (max-width: 960px) {
    .ShopTable-rowIndex {
        margin-bottom: 10px;
        text-align: center;
    }
}

.ShopTable-rowAdress{
    flex: 0 0 35%;
    max-width: 35%;
    padding: 0 8px;
    box-sizing: border-box;
    font-weight: 400;
    /* white-space: pre-wrap; */
    word-wrap: break-word;
}

@media(max-width:960px){
    .ShopTable-rowAdress{
        max-width: 100%;
    }
}

.ShopTable-action{
    flex: 1 1 auto;
    text-align: center;
}

.ShopTable-action{
    opacity: 0;
}

@keyframes actionAppearance{
    0% {
        opacity: 0;
      }

    100% {
        opacity: 1;
    }
}

.ShopTable-row:hover .ShopTable-action{
    animation-name: actionAppearance;
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
}

@media (max-width: 840px) {
    .ShopTable-row:hover {
        background-color: transparent;
    }
}
