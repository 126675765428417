.Price {
  display: inline-block;
  font-weight: 500;
  color: #2E2E2E;
  letter-spacing: 0.08em;
}

.Price--variant-current {
}

.Price--variant-gift {
  color: #FF5660;
}

.Price--variant-previous {
  position: relative;
  font-weight: 300;
  color: #A0A0A0;
  font-size: 14px;
  line-height: 14px;
}

.Price--variant-previous::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  display: inline-block;
  border-top: 1px solid #A0A0A0;
  transform: rotate(15deg);
  opacity: 0.5;
}

.Price--variant-sale {
  color: #FF5660;
  line-height: 22px;
  font-size: 22px;
}

.Price-number {
}

.Price-currency {
}

@media (max-width: 640px) {
  .Price--variant-sale {
    font-size: 16px;
    line-height: 16px;
  }
}
