.PriceGroup {
  display: inline-flex;
  margin: -0.4em;
  align-items: center;
}

.PriceGroup--columnDirection {
  flex-direction: column;
  margin: -0.1em;
}

.PriceGroup-item {
  flex: 0 0 auto;
  display: inline-block;
  padding: 0.4em;
}

.PriceGroup--columnDirection .PriceGroup-item {
  padding: 0.1em;
}
