.MagnitGiftsDialog-title{
    text-align: left;
    font-weight: 300;
    line-height: 24px;
}

@media(max-width:840px){
    .MagnitGiftsDialog-title{
        font-size: 16px ;
        line-height: 18px ;
        padding: 0 24px;
    }
}

.MagnitGiftsDialog-backAction{
    border-bottom: 1px solid #C4C4C4;
    margin: 0 -72px;
}

@media(max-width:840px){
    .MagnitGiftsDialog-backAction{
        border-bottom: none;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1); 
        background-color: #fff;
    }
}



.MagnitGiftsDialog-backActionButton{
    position: relative;
    border: none;
    background-color: transparent;
    outline: none;
    padding: 32px;
    /* margin: 0 72px; */
    width: 100%;
    /* width: calc(100% - 144px); */
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

@media(max-width:480px){
    .MagnitGiftsDialog-backActionButton{
        padding: 32px 130px;
        padding-right: 90px;
    }
}


.MagnitGiftsDialog-backActionButton-iconWrapper{
    position: absolute;
    left: 6%;
    top: 30%;
}

@media(max-width:840px){
    .MagnitGiftsDialog-backActionButton-iconWrapper{
        left: 10%;
    } 
}

@media(max-width:720px){
    .MagnitGiftsDialog-backActionButton-iconWrapper{
        left: 14%;
    } 
}

@media(max-width:480px){
    .MagnitGiftsDialog-backActionButton-iconWrapper{
        left: 16%;
    } 
}

@media(max-width:420px){
    .MagnitGiftsDialog-backActionButton-iconWrapper{
        left: 18%;
    } 
}

.MagnitGiftsDialog-backActionTitle{
    width: 360px;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
}

@media(max-width:840px){
    .MagnitGiftsDialog-backActionTitle{
        font-size: 12px;
        line-height: 14px;
    } 
}

.MagnitGiftsDialog-shop{
    position: relative;
}

.MagnitGiftsDialog-shopLoader{
    position: absolute;
    background-color: rgba(255, 255, 255, 0.6);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1001;
}

.MagnitGiftsDialog-filter{
    padding: 0 64px;
    margin-bottom: 48px;
}

@media(max-width:640px) {
    .MagnitGiftsDialog-filter{
        padding: 0 16px;
        margin-bottom: 18px;
    } 
}

.MagnitGiftsDialog-productGrid{
    width: 820px;
}

@media(max-width:840px) {
    .MagnitGiftsDialog-productGrid{
        width: 100%;
    }
}

.MagnitGiftsDialog-productGridTitle{
    margin: 0;
    padding: 8px 64px 40px;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
}

.MagnitGiftsDialog-productGridPositioner {
    position: relative;
    max-height: calc(100vh - 400px);
    display: flex;
}
  
@media (max-width: 840px) {
    .MagnitGiftsDialog-productGridPositioner {
        overflow: auto;
        flex-direction: column;
        max-height: calc(100vh - 160px);
    }
}

.MagnitGiftsDialog-productGridList{
    padding: 0 64px;
}

@media (max-width: 480px) {
    .MagnitGiftsDialog-productGridList{
        padding: 0 16px;
    }
}

.MagnitGiftsDialog-productGridItem{
    outline: none;
    border: none;
    background-color: transparent;
    display: flex;
    width: 100%;
    cursor: pointer;
    margin-bottom: 20px;
}

.MagnitGiftsDialog-productGridItem:last-child{
    margin-bottom: 0;
}

.MagnitGiftsDialog-productGridItemMedia{
    flex: 0 0 45%;
}

.MagnitGiftsDialog-productGridItemInfo{
    flex: 1 1 55%;
    padding-left: 20px;
    box-sizing: border-box;
    font-weight: 400;
}

.MagnitGiftsDialog-productGridItemInfoTitle{
    text-align: left;
    margin-bottom: 42px;
    font-size: 14px;
    line-height: 18px;
}

@media(max-width:560px) {
    .MagnitGiftsDialog-productGridItemInfoTitle{
        font-size: 12px;
        line-height: 16px;
    }
}

@media(max-width:560px) {
    .MagnitGiftsDialog-productGridItemInfoTitle{
        margin-bottom: 16px;
    }
}

.MagnitGiftsDialog-productGridItemInfoDescription{
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 16px;
}

@media(max-width:560px) {
    .MagnitGiftsDialog-productGridItemInfoDescription{
        margin-bottom: 8px;
        font-size: 10px;
    }
}

.MagnitGiftsDialog-productGridItemInfoNote{
    font-size: 10px;
    line-height: 14px;
    color: #747474;
}

@media(max-width:560px) {
    .MagnitGiftsDialog-productGridItemInfoNote{
        font-size: 8px;
    }
}

