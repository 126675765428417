.Form {
}

.Form-row {
  margin-bottom: 40px;
}

@media (max-width: 840px) {
  .Form-row {
    margin-bottom: 24px;
  }
}

.Form-row:last-child {
  margin-bottom: 0;
}
