.BasketProductList {
  display: flex;
  height: 100%;
}

.BasketProductList-el {
  margin-bottom: 16px;
}

.BasketProductList-textContent {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.08em;
}

.BasketProductList-boldTextContent {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.08em;
}

.BasketProductList-customText {
  max-width: 100% !important;
}

.BasketProductList-customImage {
  width: auto;
  height: 73px;
  flex-shrink: 0;
  object-fit: contain;
}

@media (max-width: 500px) {
  .BasketProductList-customText {
    font-size: 12px;
    line-height: 16px;
  }

  .BasketProductList-customImage {
    height: 61px;
    flex-shrink: 0;
  }

  .BasketProductList-boldTextContent {
    font-size: 12px;
    line-height: 12px;
  }

  .BasketProductList-textContent {
    font-size: 12px;
    line-height: 16px;
  }
}

@media (max-width: 840px) {
  .BasketProductList-el {
    overflow: auto;
    width: 100%;
  }
}

.BasketProductList-item {
  padding: 16px 0;
  border-bottom: 1px solid #e8e8e8;
  overflow: hidden;
}

.BasketProductList-item:first-child {
  padding-top: 0;
}

.BasketProductList-alertSkeleton {
  display: block;
  line-height: 1;
  height: 28px;
  margin-top: 10px;
}

@media (max-width: 420px) {
  .BasketProductList-alertSkeleton {
    height: 20px;
    margin-top: 7px;
  }
}

.BasketProductList-cakeSkeleton {
  display: block;
  line-height: 1;
  height: 73px;
}

.BasketProductList-cakeAwardsCard {
  padding-top: 16px;
}

@media (max-width: 330px) {
  .BasketProductList-customText {
    margin-left: 5px;
  }
}

.BasketProductList-item--info {
  margin-top: 10px;
  padding: 8px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--light-violet);
  color: var(--main-violet);
  line-height: 1;
  letter-spacing: 0.03em;
  font-size: 12px;
  cursor: default;
}

@media (max-width: 420px) {
  .BasketProductList-item--info {
    margin-top: 7px;
    padding: 5px 10px;
    font-size: 10px;
  }
}
