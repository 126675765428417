.PromocodeInputSuggestion {
}

.PromocodeInputSuggestion-button {
  display: inline-block;
  height: 44px;
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  margin: 0;
  border: 1px dashed var(--main-violet);
  background-color: transparent;
  font-family: inherit;
  font-size: 12px;
  font-weight: 300;
  color: var(--main-violet);
  letter-spacing: inherit;
  text-align: left;
  cursor: pointer;
  outline: none;
  appearance: none;
  transition: opacity 0.2s ease-in-out;
}

.PromocodeInputSuggestion-button:focus {
  opacity: 0.8;
}

.PromocodeInputSuggestion-button:hover {
  opacity: 0.8;
}

.PromocodeInputSuggestion-button:active {
  opacity: 1;
}
