.Promocode {
}

.Promocode-el {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
}

.Promocode-el-appear,
.Promocode-el-appear-active,
.Promocode-el-appear-done {
  position: relative;
  z-index: 3;
  opacity: 1;
}

.Promocode-el-enter,
.Promocode-el-enter-active,
.Promocode-el-enter-done {
  position: relative;
  z-index: 3;
  opacity: 1;
}
