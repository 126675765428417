.Dialog {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.Dialog--isHightPriority {
  z-index: 1100;
}

.Dialog--noSideInnerIndents {
}

.Dialog--scrolable {
}

.Dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100%;
}

.Dialog-window {
  position: relative;
  min-width: 480px;
  min-height: 320px;
  padding: 24px;
  padding-top: 64px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
}

@media (max-width: 840px) {
  .Dialog-window {
    width: 100%;
    height: 100%;
    padding: 16px;
    min-width: auto;
  }

  .Dialog--desktopViewForMobile .Dialog-window {
    width: auto;
    height: auto;
  }
}

.Dialog--noSideInnerIndents .Dialog-window {
  padding-left: 0;
  padding-right: 0;
}

.Dialog--noBottomInnerIndents .Dialog-window {
  padding-bottom: 0;
}

.Dialog--scrolable .Dialog-window {
  overflow-y: auto;
}

.Dialog--noTopIndens .Dialog-window {
  padding-top: 0;
}

.Dialog--optimalTopIndens .Dialog-window {
  padding-top: 35px;
}

.Dialog--disableBorderRadius .Dialog-window {
  border-radius: 0;
}

@media (max-width: 840px) {
  .Dialog--mobileFullHeight .Dialog-content {
    height: 100%;
    overflow-y: auto;
  }
}

.Dialog-title {
  margin: 0;
  margin-bottom: 32px;
  line-height: 1.2;
  padding: 0 72px;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
}

@media (max-width: 840px) {
  .Dialog-title {
    padding: 0;
    padding-right: 40px;
    padding-left: 16px;
    margin-bottom: 24px;
    text-align: left;
  }
}

.Dialog-closeButtonHolder {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 999;
}

@media (max-width: 840px) {
  .Dialog-closeButtonHolder {
    top: 8px;
    right: 8px;
  }
}

.Dialog-closeButton {
  display: inline-block;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  line-height: 1;
  font-size: 36px;
  outline: none;
  appearance: none;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  vertical-align: top;
  z-index: 999;
}

.Dialog-closeButton:hover {
  opacity: 0.6;
}

.Dialog-closeButton:focus {
  opacity: 0.6;
}

.Dialog-closeButton:active {
  opacity: 1;
}
