.BasketRightSideDialog {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding: 24px 0;
}

.BasketRightSideDialog-skeletonBlock {
  display: block;
  line-height: 1;
}

.BasketRightSideDialog-emptyMessageHolder {
  flex: 1 1 auto;
  display: flex;
}

.BasketRightSideDialog-emptyMessage {
  margin: auto;
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
}

.BasketRightSideDialog-header {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 56px;
}

.BasketRightSideDialog-closeButtonHolder {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.BasketRightSideDialog-closeButton {
  display: inline-block;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  appearance: none;
  outline: none;
  transition: opacity 0.2s ease-in-out;
}

.BasketRightSideDialog-closeButton:focus {
  opacity: 0.7;
}

.BasketRightSideDialog-closeButton:hover {
  opacity: 0.7;
}

.BasketRightSideDialog-closeButton:active {
  opacity: 1;
}

.BasketRightSideDialog-title {
  margin: 0;
  line-height: 1.2;
  font-size: 24px;
  font-weight: 300;
  text-transform: uppercase;
}

.BasketRightSideDialog-amountButton {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.BasketRightSideDialog-productListHolder {
  position: relative;
  flex: 1 1 auto;
  margin-bottom: 16px;
  margin-right: -24px;
  overflow: hidden;
}

.BasketRightSideDialog-productListPositioner {
  position: absolute;
  left: 0;
  right: 24px;
  bottom: 0;
  top: 0;
}

.BasketRightSideDialog-totalGroup {
  flex: 0 0 auto;
}

.BasketRightSideDialog-promocode {
  margin-bottom: 16px;
}

.BasketRightSideDialog-totalRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  font-size: 18px;
}

@media (min-width: 1280px) {
  .BasketRightSideDialog-totalRow {
    margin-bottom: 7px;
  }
}

.BasketRightSideDialog-partsPaymentsRow {
  margin-bottom: 10px;
  width: fit-content;
  margin-left: auto;
}

@media (min-width: 1280px) {
  .BasketRightSideDialog-partsPaymentsRow {
    margin-bottom: 16px;
  }
}

.BasketRightSideDialog-totalKey {
  text-transform: uppercase;
  position: relative;
}

.BasketRightSideDialog-totalKeySkeleton {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.BasketRightSideDialog-totalValue {
  position: relative;
}

.BasketRightSideDialog-totalValueSkeleton {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.BasketRightSideDialog-orderLink {
}

.BasketRightSideDialog-headerSkeletonWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 56px;
  padding-right: 6px;
  padding-left: 2px;
}
