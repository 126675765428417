.sideMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.bottomMenu {
  padding-bottom: 16px;
}

.closeIcon {
  position: absolute;
  top: 24px;
  right: 24px;
  margin: 0;
  padding: 0;
  font-size: 36px;
  line-height: 1;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  appearance: none;
}
